import React, { Component } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import AddIconModal from "../components/AddIconModal";
import Slider from "../components/modals/Slider";
import FeeModal from "../components/FeeModal";
import WaxCoin from "../assets/images/waxlogocoin.png";
import PairingModal from "../components/PairingModal";
import PairingIcon from "../assets/images/pairingicon.png";
import FactorModal from "../components/FactorModal";
import WonModal from "../components/WonModal";
import LostModal from "../components/LostModal";
import ApiServices from "../services/api-services";
import { element } from "prop-types";
import ErrorModal from "../components/ErrorModal";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import Loader from "../components/Loader/Loader";

const pageTitle = "Home -GPK Battles";

export default class MainPage extends Component {
  constructor(props) {
    super(props);
    this.cardRef = null;
    this.ipfsUrl = "https://ipfs.io/ipfs/";
    this.state = {
      user: "",
      user2: "",
      wallet: "Wallet",
      rules: "Rules",
      errorMessage: "",
      draw1Message:
        "It’s a draw, You get to play-again for free with the same player!",
      draw2Message: "It’s a Draw again, better luck next time!",
      //modals
      feeModal: false,
      pairingModal: false,
      factorModal: false,
      wonModal: false,
      lostModal: false,
      drawModal: false,
      errorModal: false,
      previousGameActiveModal: false,
      transactionFaildModal: false,
      noActivePlayerModal: false,
      firstDrawModal: false,
      loderModal: false,
      modals: { feeModal: "feeModal", pairingModal: "pairingModal", factorModal: "factorModal", wonModal: "wonModal", lostModal: "lostModal", drawModal: "drawModal", errorModal: "errorModal", previousGameActiveModal: "previousGameActiveModal", noActivePlayerModal: "noActivePlayerModal", firstDrawModal: "firstDrawModal", transactionFaildModal: "transactionFaildModal", loaderModal: "loaderModal", noModal: "noModal" },
      showModal: "noModal",
      cardSelectedModalShown: false,
      draw1: true,
      activePlayers: 0,
      userBalance: "",
      isDrawClosedOnce: false,
      wins: 0,
      loses: 0,
      draws: 0,
      isLoading: true,
      addModal1: false,
      addModal2: false,
      addModal3: false,
      previousGame: false,
      isDraw: false,
      firstTime: false,
      gameId: null,
      sliderCardData: [],
      selectedCards: [{ img: null }, { img: null }, { img: null }],
      selectedCards2: [{ img: null }, { img: null }, { img: null }],
      winnerCard: { img: null, name: null },
      mainCards: [],
      gameStatus: [
        "cardsSelecting",
        "pairing",
        "getGameId",
        "playGame",
        "gameResult",
        "complete",
      ],
    };

    this.myGameStatus = null;
    this.animationTime = 1000;
    this.apiService = new ApiServices();
  }

  async componentDidMount() {
    this.setState({ user2: "" });
    let ual = this.props.ual;
    if (ual.activeUser) {
      let user = this.apiService.getAccountName(ual);
      let userBalance = await this.apiService.getUserBalance(user);
      let activePlayers = await this.apiService.getActivePlayers(ual);
      this.setState({ user, activePlayers, userBalance });
      await this.getData(user, ual);
      this.setState({ isLoading: false });
      this.myGameStatus = setInterval(async () => {
        await this.getUserGameStatus();
        await this.setGameStatus();
      }, this.animationTime);
    }
  }

  getData = async (user, ual) => {
    await this.getUserAvailableCards(user, ual);
    await this.getUserStats(user, ual);
  };

  getUserStats = async (user, ual) => {
    var res = await this.apiService.getUserGameStatus(
      this.props.ual,
      this.state.user
    );
    if (res) {
      let draws = res[0].draws;
      let loses = res[0].loses;
      let wins = res[0].wins;
      this.setState({ draws, loses, wins });
    }
  };

  getUserAvailableCards = async (user, ual) => {
    let allCards = await this.apiService.getUserCardsAvailableCards(user, ual);
    let availableCards = [];
    let selectedCards = [];
    allCards.forEach((element) => {
      if (element.usage_status == "available") availableCards.push(element);
      else selectedCards.push(element);
    });

    let cards = await this.apiService.getCardsData(availableCards, ual);
    selectedCards = await this.apiService.getCardsData(selectedCards, ual);
    cards = cards.filter(function (el) {
      return el != null;
    });
    if (cards.length > 0) {
      let sliderCardData = cards.map((element, index) => {
        return {
          img: this.ipfsUrl + element.mdata.img,
          name: element.mdata.name,
          cardid: element.mdata.cardid,
          quality: element.mdata.quality,
          rarity: element.mdata.variant,
          key: element.id,
          status: element.status
        };
      });
      this.setState({ sliderCardData });
    }
    if (selectedCards.length > 0) {
      selectedCards = selectedCards.map((element, index) => {
        return {
          img: this.ipfsUrl + element.mdata.img,
          name: element.mdata.name,
          cardid: element.mdata.cardid,
          quality: element.mdata.quality,
          rarity: element.mdata.variant,
          key: element.id,
          status: element.status,
        };
      });
      this.setState({ selectedCards, previousGame: true });
    }

    this.getUserGameStatus();
  };

  setGameStatus = async () => {
    if (this.state.gameId) {
      let userGameRow = await this.apiService.getGameRowSpecific(
        this.props.ual,
        this.state.gameId
      );
      if (userGameRow[0] && userGameRow[0].status == "over") {
        await this.apiService.setStatus(this.state.gameStatus[3]);
        await this.apiService.setData(this.state.gameId);
      } else if (userGameRow[0] && userGameRow[0].result == "draw") {
        if (
          (userGameRow[0].player_1 == this.state.user &&
            userGameRow[0].player1_cards.length > 0 &&
            userGameRow[0].player2_cards.length == 0) ||
          (userGameRow[0].player_2 == this.state.user &&
            userGameRow[0].player2_cards.length > 0 &&
            userGameRow[0].player1_cards.length == 0)
        ) {
          this.setState({ cardSelectedModalShown: true });
        }
        if (
          this.state.selectedCards[0].img != null &&
          this.state.selectedCards[0].status == "selected" &&
          this.state.selectedCards2[0].img != null
        ) {
          this.apiService.setStatus(this.state.gameStatus[2]);
          this.apiService.setData(this.state.gameId);
        } else if (
          this.state.selectedCards[0].img != null &&
          this.state.selectedCards[0].status == "selected"
        ) {
          this.apiService.setStatus(this.state.gameStatus[0]);
          this.apiService.setData("");
        } else if (
          this.state.selectedCards[0].img == null ||
          (this.state.selectedCards[0].img != null &&
            this.state.selectedCards[0].status == "available")
        ) {
          this.apiService.setStatus(this.state.gameStatus[5]);
          this.apiService.setData("");
        }
      } else if (this.state.selectedCards2[0].img != null) {
        this.apiService.setStatus(this.state.gameStatus[2]);
        this.apiService.setData(this.state.gameId);
      }
    } else {
      if (
        this.state.selectedCards[0].img != null &&
        this.state.selectedCards[0].status == "selected"
      ) {
        this.apiService.setStatus(this.state.gameStatus[0]);
        this.apiService.setData("");
      } else if (
        this.state.selectedCards[0].img == null ||
        (this.state.selectedCards[0].img != null &&
          this.state.selectedCards[0].status == "available")
      ) {
        this.apiService.setStatus(this.state.gameStatus[5]);
        this.apiService.setData("");
      }
    }
  };

  //get data
  getUserGameStatus = async () => {
    let userBalance = await this.apiService.getUserBalance(this.state.user);
    let activePlayers = await this.apiService.getActivePlayers(this.props.ual);
    this.setState({ activePlayers, userBalance });

    let gameId = this.state.gameId;
    let isPlayer1 = false;
    let playerName = "";
    let isDraw = false;
    if (!gameId) {
      let userGameRow = await this.apiService.getGameRow(this.props.ual);
      let myGame = null;

      userGameRow.forEach((element) => {
        if (
          element.player_1 == this.state.user ||
          element.player_2 == this.state.user
        )
          myGame = element;

        playerName = element.player_1;
        if (element.player_1 == this.state.user) {
          isPlayer1 = true;
          playerName = element.player_2;
        }
      });
      if (myGame && myGame.game_id) {
        gameId = myGame.game_id;
        this.apiService.setData(gameId);
        this.setState({ gameId, myGame });
        await this.getOtherUserCards(gameId);
        if (myGame.player_2 == this.state.user && myGame.player2_cards.length > 0 && myGame.player1_cards.length > 0)
          this.setState({ showModal: this.state.modals.factorModal })
        this.setState({ gameId, myGame });
      }
    } else if (gameId) {
      await this.getOtherUserCards(gameId);
      let userGameRow = await this.apiService.getGameRowSpecific(
        this.props.ual,
        gameId
      );
      let myGame = userGameRow[0];

      if (userGameRow[0] && userGameRow[0].result == "draw") {

        if (this.state.showModal == this.state.modals.loaderModal && this.state.selectedCards2[0].img != null && this.state.selectedCards[0].img != null && myGame.player_2 == this.state.user)
          this.setState({ showModal: this.state.modals.factorModal })


        let gameStartTimeStamp = userGameRow[0].start_timestamp;
        let currentTimeStamp = Date.now();
        if ((currentTimeStamp / 1000) - gameStartTimeStamp >= 180) {
          let defaulterPlayer = [];
          if (userGameRow[0].player1_cards.length == 0)
            defaulterPlayer.push(userGameRow[0].player_1)
          if (userGameRow[0].player2_cards.length == 0)
            defaulterPlayer.push(userGameRow[0].player_2)
          if (defaulterPlayer.length > 0) {
            const del1drawgameAction = this.apiService.del1drawgameAction(gameId, defaulterPlayer);
            console.log("actions = ", del1drawgameAction);
            const result = await this.apiService.defaultPushAction(del1drawgameAction);
            console.log("Result = ", result);
            await this.apiService.setlastGame("");
            await this.apiService.setStatus(this.state.gameStatus[5]);
            if (result.success) {
              window.location.reload();
            } else {
              if (!result.message.includes("duplicate transaction") && !result.message.includes("game_id"))
                this.setState({
                  showModal: this.state.modals.transactionFaildModal,
                  errorMessage: result.message,
                });
              else {
                window.location.reload();
              }
            }
          }
        } else {
          if (playerName == "") {
            playerName = userGameRow[0].player_1;
            if (userGameRow[0].player_1 == this.state.user) {
              playerName = userGameRow[0].player_2;
            }
          }
          this.setState({ isDraw: true, user2: playerName });
          let isShowed = this.apiService.getlastGame();
          if (
            isShowed != "showed" &&
            this.state.selectedCards[0].img != null &&
            this.state.selectedCards2[0].img != null
          ) {
            setTimeout(async () => {
              this.setState({ showModal: this.state.modals.drawModal, draw1: true });
            }, this.animationTime);
          }
          this.apiService.setlastGame("showed");
        }
      } //else {
      var res = await this.apiService.getUserGameStatus(
        this.props.ual,
        this.state.user
      );
      if (res) {
        let draws = res[0].draws;
        let loses = res[0].loses;
        let wins = res[0].wins;

        if (draws > this.state.draws) {
          setTimeout(async () => {
            this.apiService.setlastGame("");
            this.setState({ showModal: this.state.modals.drawModal, draw1: false });
          }, this.animationTime);
        } else if (loses > this.state.loses) {
          this.setState({ showModal: this.state.modals.lostModal });
          this.apiService.setlastGame("lose");
        } else if (wins > this.state.wins) {
          this.apiService.setlastGame("won");
          let wonCards = res[0].cards_won;
          let cardId = wonCards[wonCards.length - 1];
          let cardData = await this.apiService.getCardsData(
            [{ card_id: cardId }],
            this.props.ual
          );
          if (!cardData[0]) {
            cardData = await this.apiService.getUserCardsSimpleAssets(
              this.state.user,
              this.props.ual,
              cardId,
              1
            );
            cardData[0].mdata = JSON.parse(cardData[0].mdata);
          }
          if (cardData.length) {
            let winnerCard = {
              img: this.ipfsUrl + cardData[0].mdata.img,
              name: cardData[0].mdata.name,
            };
            this.setState({ winnerCard });
            this.setState({ showModal: this.state.modals.wonModal });
          }
        }
        //}
      }
    } else {
      var res = await this.apiService.getUserGameStatus(
        this.props.ual,
        this.state.user
      );
      if (res) {
        let draws = res[0].draws;
        if (draws > this.state.draws && isPlayer1) {
          setTimeout(async () => {
            await this.apiService.setStatus(this.state.gameStatus[4]);
            this.setState({ showModal: this.state.modals.drawModal });
          }, this.animationTime);
        }
      }
    }
  };

  isCardSelected = () => {
    let selectedCards = this.state.selectedCards;

    if (selectedCards[0].img != null && selectedCards[0].status == "selected") {
      return true;
    }

    return false;
  };

  selectedCardHandler = (index, position) => {
    let sliderCardData = this.state.sliderCardData;
    let selectedCards = this.state.selectedCards;

    if (!this.state.isLoading && !this.isCardSelected()) {
      var card = this.state.sliderCardData[index];
      var cardValidationData = this.checkValidation();

      console.log("Card = ", card);

      if (selectedCards[position - 1].img == null) {
        if (
          (card.quality == "a" && cardValidationData.aVariant < 2) ||
          (card.quality == "b" && cardValidationData.bVariant < 2)
        ) {
          selectedCards[position - 1] = card;
          sliderCardData.splice(index, 1);
        } else {
          console.log("Can't select " + card.quality + " type card ");
          this.handleErrorModal("Can't select " + card.quality + " type card ");
        }
      } else {
        console.log("cardValidationData = ", cardValidationData);
        if (
          (card.quality == "a" && cardValidationData.aVariant < 2) ||
          (card.quality == "b" && cardValidationData.bVariant < 2)
        ) {
          let tempCard = selectedCards[position - 1];
          selectedCards[position - 1] = card;
          sliderCardData.splice(index, 1);
          sliderCardData.push(tempCard);
        } else {
          console.log("Can't select " + card.quality + " type card ");
          this.handleErrorModal("Can't select " + card.quality + " type card ");
        }
      }

      if (position == 1)
        this.setState({ selectedCards, addModal1: false, sliderCardData });
      else if (position == 2)
        this.setState({ selectedCards, addModal2: false, sliderCardData });
      else if (position == 3)
        this.setState({ selectedCards, addModal3: false, sliderCardData });
    } else {
      this.handleErrorModal("Cards are selected already");
    }
  };

  autoPlay = () => {
    if (!this.state.isLoading) {
      let availableCards = this.state.sliderCardData;
      let selectedCards = this.state.selectedCards;

      availableCards = availableCards.concat(selectedCards);

      let isSelected = false;

      availableCards = Array.from(
        new Set(availableCards.map((a) => a.key))
      ).map((key) => {
        return availableCards.find((a) => a.key === key);
      });

      let index = availableCards.findIndex((el) => {
        return el.img == null;
      });
      if (index > 0) {
        availableCards.splice(index, 1);
      }

      if (!this.isCardSelected() && availableCards.length > 0) {
        let { count, aVariant, bVariant } = this.checkValidation();
        let autoSelectedCards = [];
        count = 0;
        aVariant = 0;
        bVariant = 0;
        let cardsLength2 = availableCards.length;
        for (var i = 0; i <= cardsLength2 && count < 3; i++) {
          let cardsLength = availableCards.length;
          if (cardsLength > 0) {
            let randomNumber = Math.floor(Math.random() * 100) % cardsLength;
            if (availableCards[randomNumber].quality) {
              let tempVariant = availableCards[randomNumber].quality;
              if (tempVariant == "a" && aVariant < 2) {
                autoSelectedCards.push(availableCards[randomNumber]);
                availableCards.splice(randomNumber, 1);
                aVariant++;
                count++;
              } else if (tempVariant == "b" && bVariant < 2) {
                autoSelectedCards.push(availableCards[randomNumber]);
                availableCards.splice(randomNumber, 1);
                bVariant++;
                count++;
              }
            }
          }
        }
        let j = 0;
        let sliderCardData = this.state.sliderCardData;
        for (var i = 0; autoSelectedCards.length > 0; i++) {
          if (selectedCards[i]) {
            selectedCards[i] = autoSelectedCards[j];
            autoSelectedCards.splice(j, 1);
          }
        }

        this.setState({ selectedCards, sliderCardData: availableCards });
      } else {
        this.handleErrorModal(
          "No available Cards or Cards are selected already"
        );
      }
    }
  };

  addIconModalShow1 = () => {
    this.setState({ addModal1: true });
  };

  addIconModalClose1 = () => {
    this.setState({ addModal1: false });
  };
  addIconModalShow2 = () => {
    this.setState({ addModal2: true });
  };
  addIconModalClose2 = () => {
    this.setState({ addModal2: false });
  };
  addIconModalShow3 = () => {
    this.setState({ addModal3: true });
  };
  addIconModalClose3 = () => {
    this.setState({ addModal3: false });
  };
  playGame = async () => {
    if (!this.state.isLoading) {
      let { count, aVariant, bVariant } = this.checkValidation();
      if (
        count == 3 &&
        ((aVariant == 2 && bVariant == 1) || (aVariant == 1 && bVariant == 2))
      ) {
        let gameStatus = this.apiService.getStatus();
        let data = this.apiService.getData();
        if (
          gameStatus == this.state.gameStatus[0] &&
          !this.state.isDraw &&
          !this.state.cardSelectedModalShown
        ) {
          if (this.state.activePlayers > 1)
            await this.pairWithPlayer();
          else
            this.setState({ showModal: this.state.modals.previousGameActiveModal })
        }
        else if (gameStatus == this.state.gameStatus[1] && !this.state.isDraw)
          await this.play(data);
        else if (gameStatus == this.state.gameStatus[2])
          await this.playGameContract(data);
        else if (gameStatus == this.state.gameStatus[3])
          await this.gameResult(data);
        else if (gameStatus == this.state.gameStatus[4]) {
          this.wonModalClose();
          this.lostModalClose();
        } else if (!this.state.previousGame || this.state.isDraw)
          await this.transferFee();
      } else {
        this.handleErrorModal(
          "Select 3 cards (1a and 2b or 2a and 1b) to play."
        );
      }

      // await this.pairWithPlayer();
      // await this.play("69b42d77416708dd4a7eef0905321accc650edee900aeea06829a1bc03a30244");
      // await this.gameResult("10001733732578");
      // await this.playGameContract("10001733729327");

      // this.setState({ feeModal: true });
      // setTimeout(() => {
      //   this.setState({ feeModal: false, pairingModal: true });
      // }, 5000);
      // setTimeout(() => {
      //   this.setState({ pairingModal: false, factorModal: true });
      // }, 10000);
      // setTimeout(() => {
      //   this.setState({ factorModal: false, wonModal: true });
      // }, 15000);
    }
  };

  checkValidation = () => {
    let selectedCards = this.state.selectedCards;
    let count = 0;
    let aVariant = 0;
    let bVariant = 0;

    selectedCards.map((element) => {
      if (element.img) {
        count++;
        element.quality == "a" ? aVariant++ : bVariant++;
      }
    });

    return { count, aVariant, bVariant };
  };

  cardAvailibility = (cards) => {
    let count = 0;
    let aVariant = 0;
    let bVariant = 0;

    cards.map((element) => {
      if (element.img) {
        count++;
        element.quality == "a" ? aVariant++ : bVariant++;
      }
    });

    return { count, aVariant, bVariant };
  };

  transferFee = async () => {
    if (!this.state.isLoading) {
      const transferFee = this.apiService.tokenTransferAction(this.state.user);
      const selectCardsAction = this.apiService.selectCards(
        this.state.user,
        this.state.selectedCards[0].key,
        this.state.selectedCards[1].key,
        this.state.selectedCards[2].key
      );
      transferFee.actions[1] = selectCardsAction.actions[0];
      if (!this.state.cardSelectedModalShown) {
        if (this.state.isDraw) {
          transferFee.actions.splice(0, 1);
          this.handleErrorModal("Creating Transaction");
        } else {
          this.setState({ showModal: this.state.modals.feeModal });
        }
        setTimeout(async () => {
          const result = await this.apiService.pushTransaction(
            transferFee,
            this.props.ual
          );

          if (result.success) {
            let selectedCards = this.state.selectedCards;
            selectedCards = selectedCards.map((element) => {
              element.status = "selected"
              return element;
            })
            this.setState({ selectedCards })
            setTimeout(() => {
              this.setState({ showModal: this.state.modals.noModal });
            }, this.animationTime);
            if (!this.state.isDraw)
              setTimeout(async () => {
                await this.apiService.setStatus(this.state.gameStatus[0]);
                await this.pairWithPlayer();
              }, this.animationTime);
            else
              setTimeout(async () => {
                if (this.state.selectedCards2[0].img) {
                  this.handleErrorModal("");
                  await this.apiService.setStatus(this.state.gameStatus[2]);
                  await this.apiService.setData(this.state.gameId);
                  await this.playGameContract(this.state.gameId);
                } else {
                  this.handleErrorModal("");
                  await this.apiService.setStatus(this.state.gameStatus[0]);
                  await this.apiService.setData(this.state.gameId);
                  this.setState({ showModal: this.state.modals.loaderModal });
                }
              }, this.animationTime);
          } else {
            this.handleErrorModal("");
            this.setState({ showModal: this.state.modals.noModal });
            this.setState({
              showModal: this.state.modals.transactionFaildModal,
              errorMessage: result.message,
            });
          }
        }, this.animationTime);
      } else {
        await this.apiService.setStatus(this.state.gameStatus[1]);
        await this.apiService.setData(this.state.gameId);
      }
    }
  };

  pairWithPlayer = async () => {
    if (!this.state.isLoading && !this.state.isDraw) {
      let userGameRow = await this.apiService.getGameRow(this.props.ual);
      let myGame = null;
      userGameRow.forEach((element) => {
        if (
          element.player_1 == this.state.user ||
          element.player_2 == this.state.user
        )
          myGame = element;
      });

      if (!myGame) {
        this.setState({ showModal: this.state.modals.pairingModal });
        setTimeout(async () => {
          const pairWPlayerAction = this.apiService.pairWithPlayer(
            this.state.user
          );
          const result = await this.apiService.pushTransaction(
            pairWPlayerAction,
            this.props.ual
          );
          if (result.success) {
            setTimeout(() => {
              this.setState({ showModal: this.state.modals.noModal });
            }, this.animationTime);
            setTimeout(async () => {
              await this.apiService.setData(result.transactionId);
              await this.apiService.setStatus(this.state.gameStatus[1]);
              await this.play(result.transactionId);
            }, this.animationTime);
          } else {
            if (result.message.includes("CPU")) {
              this.setState({ showModal: this.state.modals.noModal });
              this.setState({ errorMessage: result.message, showModal: this.state.modals.errorModal });
            } else {
              this.setState({ showModal: this.state.modals.noModal });
              this.setState({ showModal: this.state.modals.noActivePlayerModal });
            }
          }
        }, this.animationTime);
      }
    }
  };

  play = async (id) => {
    try {
      var gameId = await this.apiService.getGameId(id, this.props.ual);
      this.setState({ gameId });
      await this.apiService.setData(gameId);
      await this.apiService.setStatus(this.state.gameStatus[2]);
      await this.playGameContract(gameId);
    } catch (e) {
      var gameId = await this.getGameId();
      if (gameId) {
        this.setState({ gameId });
        await this.apiService.setData(gameId);
        await this.apiService.setStatus(this.state.gameStatus[2]);
        await this.playGameContract(gameId);
      }
    }
  };

  getGameId = async () => {
    let userGameRow = await this.apiService.getGameRow(this.props.ual);
    let myGame = null;

    userGameRow.forEach((element) => {
      if (
        element.player_1 == this.state.user ||
        element.player_2 == this.state.user
      )
        myGame = element;
    });
    if (myGame && myGame.game_id) {
      return myGame.game_id;
    }
    return myGame;
  };

  playGameContract = async (id) => {
    if (!this.state.cardSelectedModalShown) {
      let gameId = id;
      this.setState({ showModal: this.state.modals.factorModal });
      setTimeout(async () => {
        await this.getOtherUserCards(gameId);
        const playGameAction = this.apiService.play(this.state.user, gameId);
        // const result = await this.apiService.pushTransaction(
        //   playGameAction,
        //   this.props.ual
        // );
        const result = await this.apiService.defaultPushAction(playGameAction);
        console.log("Result = ", result);
        if (result.success) {
          setTimeout(() => {
            this.setState({ showModal: this.state.modals.noModal });
          }, this.animationTime);
          setTimeout(async () => {
            await this.apiService.setStatus(this.state.gameStatus[3]);
            await this.gameResult(gameId);
          }, this.animationTime);
        } else {
          this.setState({ showModal: this.state.modals.noModal });
          this.setState({
            showModal: this.state.modals.transactionFaildModal,
            errorMessage: result.message,
          });
        }
      }, this.animationTime);
    }
    // else {
    //   this.setState({ showModal: this.state.modals.transactionFaildModal });
    // }
  };

  getOtherUserCards = async (gameId) => {
    if (this.state.selectedCards2[0].img == null) {
      let cardsSelected = false;
      var res = await this.apiService.getGameResult(this.props.ual, gameId);
      if (res && res.player_1 && res.player_2) {
        let player1 = res.player_1;
        let player2 = res.player_2;
        let playerCards = [];
        if (player1 != this.state.user) {
          playerCards = res.player1_cards;
        } else if (player2 != this.state.user) {
          playerCards = res.player2_cards;
        }
        if (res.player1_cards.length > 0 && res.player2_cards.length > 0)
          await this.setOtherCardData(playerCards);
      }
    }
  };

  setOtherCardData = async (playerCards) => {
    if (playerCards.length > 0) {
      let cards = playerCards.map((element) => {
        return {
          card_id: element,
        };
      });
      let selectedCards2 = await this.apiService.getCardsData(
        cards,
        this.props.ual
      );

      selectedCards2 = selectedCards2.map((element, index) => {
        return {
          img: this.ipfsUrl + element.mdata.img,
          name: element.mdata.name,
          cardid: element.mdata.cardid,
          quality: element.mdata.quality,
          rarity: element.mdata.variant,
          key: element.id,
        };
      });
      this.setState({ selectedCards2 });
    }
  };

  gameResult = async (id) => {
    var gameRow = await this.apiService.getGameResult(this.props.ual, id);
    let result = gameRow.result;
    if (result == "nodraw") {
      this.handleErrorModal("Loading.....");
      let winerName = gameRow.winner;
      if (winerName == this.state.user) {
        let cardId = gameRow.card_won;
        let cardData = await this.apiService.getCardsData(
          [{ card_id: cardId }],
          this.props.ual
        );
        let winnerCard = {
          img: this.ipfsUrl + cardData[0].mdata.img,
          name: cardData[0].mdata.name,
        };
        this.setState({ winnerCard });

        const disandCardAction = this.apiService.disburseAction(id);

        setTimeout(async () => {
          // const result = await this.apiService.pushTransaction(
          //   disandCardAction,
          //   this.props.ual
          // );
          const result = await this.apiService.defaultPushAction(
            disandCardAction
          );
          if (result.success) {
            this.handleErrorModal("");
            this.setState({ wonModal: true });
            this.apiService.setlastGame("won");
          } else {
            this.handleErrorModal("");
            this.setState({
              showModal: this.state.modals.transactionFaildModal,
              errorMessage: result.message,
            });
            console.log("Transaction failed = ", result.message);
          }
        }, this.animationTime);
      } else {
        const disandCardAction = this.apiService.disburseAction(id);
        setTimeout(async () => {
          // const result = await this.apiService.pushTransaction(
          //   disandCardAction,
          //   this.props.ual
          // );
          const result = await this.apiService.defaultPushAction(
            disandCardAction
          );
          if (result.success) {
            this.handleErrorModal("");
            this.setState({ lostModal: true });
            this.apiService.setlastGame("lose");
          } else {
            this.handleErrorModal("");
            this.setState({
              showModal: this.state.modals.transactionFaildModal,
              errorMessage: result.message,
            });
            console.log("Transaction failed = ", result.message);
          }
        }, this.animationTime);
      }
      await this.apiService.setStatus(this.state.gameStatus[4]);
    } else {
      await this.apiService.setStatus(this.state.gameStatus[4]);
      setTimeout(async () => {
        this.setState({ showModal: this.state.modals.drawModal });
        this.apiService.setlastGame("draw");
      }, this.animationTime);
    }
  };

  wonModalClose = () => {
    this.setState({ showModal: this.state.modals.noModal });
    this.apiService.setStatus(this.state.gameStatus[5]);
    this.apiService.setData("");
    window.location.reload();
  };
  lostModalShow = () => {
    this.setState({ lostModal: true });
  };
  handleErrorModal = (errorMessage) => {
    if (errorMessage != "") this.setState({ showModal: this.state.modals.errorModal, errorMessage });
    else this.setState({ showModal: this.state.modals.noModal, errorMessage });
  };
  lostModalClose = () => {
    this.setState({ showModal: this.state.modals.noModal });
    this.apiService.setStatus(this.state.gameStatus[5]);
    this.apiService.setData("");
    window.location.reload();
  };
  drawModalClose = async () => {
    // var gameStatus = this.apiService.getStatus();
    // this.apiService.setlastGame("showed");
    // if (
    //   (
    //     gameStatus == this.state.gameStatus[4] ||
    //     gameStatus == this.state.gameStatus[3] ||
    //     gameStatus == this.state.gameStatus[2]) && (this.state.selectedCards2[0].img != null)
    // ) {
    this.apiService.setStatus(this.state.gameStatus[5]);
    // this.apiService.setData("");
    // this.apiService.setlastGame("");
    window.location.reload();
    // }
    this.setState({ showModal: this.state.modals.noModal, isDrawClosedOnce: true });
  };
  loaderModalClose = async () => {
    this.setState({ showModal: this.state.modals.noModal });
  };
  prevGameActiveModalClose = () => {
    this.setState({ showModal: this.state.modals.noModal });
  };
  transactionFaildModalClose = () => {
    this.setState({ showModal: this.state.modals.noModal });
  };
  noActivePlayerModalClose = () => {
    this.setState({ showModal: this.state.modals.noModal });
  };
  logout = () => {
    if (this.props.ual.activeUser) this.props.ual.logout();
  };

  handleModal = () => {
    let modal = null;
    if (this.state.showModal == this.state.modals.feeModal) {
      modal = (
        <FeeModal show={true}>
          <div className="wax-wallet-icon-container">
            <img src={WaxCoin} alt="Wax Coin" />
            <i className="fas fa-angle-double-right"></i>
            <i className="fas fa-wallet"></i>
          </div>
          <div className="fee-modal-title">Transfering Fee</div>
        </FeeModal>
      );
    } else if (this.state.showModal == this.state.modals.pairingModal) {
      modal = (
        <PairingModal show={true}>
          <div className="wax-wallet-icon-container">
            <img src={PairingIcon} alt="Pairing Icon" />
            <i className="fas fa-exchange-alt"></i>
            <img src={PairingIcon} alt="Pairing Icon" />
          </div>
          <div className="fee-modal-title">Pairing Players</div>
        </PairingModal>
      );
    } else if (this.state.showModal == this.state.modals.factorModal) {
      modal = (
        <FactorModal show={true}>
          <div className="wax-wallet-icon-container">
            <div className="factor-icon animation-factor-a">a</div>
            <div className="factor-icon-line"></div>
            <div className="factor-icon animation-factor-b">b</div>
          </div>
          <div className="fee-modal-title">Deciding Factor</div>
        </FactorModal>
      );
    } else if (this.state.showModal == this.state.modals.wonModal) {
      modal = (
        <WonModal show={true}>
          <div className="won-modal-title">You Won</div>
          <div className="won-modal-card-container">
            <div className="won-modal-card-img">
              <img
                src={this.state.winnerCard.img}
                alt={this.state.winnerCard.img}
              />
            </div>
          </div>
          <div className="won-modal-card-title">
            {this.state.winnerCard.name}
          </div>
          <div className="won-modal-decs">Cards Transferred to Wallet</div>
          <button className="won-modal-btn" onClick={this.wonModalClose}>
            Play Again
          </button>
        </WonModal>
      );
    } else if (this.state.showModal == this.state.modals.lostModal) {
      modal = (
        <LostModal show={true}>
          <div className="won-modal-title">You Lost</div>
          <div className="won-modal-card-title">Better luck next time</div>
          <button className="won-modal-btn" onClick={this.lostModalClose}>
            Play Again
          </button>
        </LostModal>
      );
    } else if (this.state.showModal == this.state.modals.drawModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">
            {this.state.draw1 == true
              ? this.state.draw1Message
              : this.state.draw2Message}
          </div>
          <button className="won-modal-btn" onClick={this.drawModalClose}>
            OK
          </button>
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.errorModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">{this.state.errorMessage}</div>
          {this.state.errorMessage == "Creating Transaction" ? (
            <div className="error-modal-title">Loading . . . .</div>
          ) : (
              <button
                className="won-modal-btn"
                onClick={() => this.handleErrorModal("")}
              >
                OK
              </button>
            )}
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.previousGameActiveModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">
            Seems like your previous game is still active. We are still waiting
            to match you with other players
          </div>
          <button
            className="won-modal-btn"
            onClick={this.prevGameActiveModalClose}
          >
            OK
          </button>
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.transactionFaildModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">Transaction Failed</div>
          <div className="error-modal-title">{this.state.errorMessage}</div>
          <button
            className="won-modal-btn"
            onClick={this.transactionFaildModalClose}
          >
            OK
          </button>
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.noActivePlayerModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">
            No active players! Probably 😴.You can return back at a later time
            and the current game result will be shown
          </div>
          <button
            className="won-modal-btn"
            onClick={this.noActivePlayerModalClose}
          >
            OK
          </button>
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.firstDrawModal) {
      modal = (
        <ErrorModal show={true}>
          <div className="error-modal-title">
            {
              "Waiting for the other player to select the cards. If the other player doesn’t select the cards after 180secs you will be declared as the winner!"
            }
          </div>
          <button
            className="won-modal-btn"
            onClick={() => {
              this.setState({ showModal: this.state.modals.noModal });
            }}
          >
            OK
          </button>
        </ErrorModal>
      );
    } else if (this.state.showModal == this.state.modals.loaderModal) {
      modal = (
        <ErrorModal show={true}>
          <Loader />
          <div className="error-modal-title">
            Waiting for the other player to select the cards
          </div>
          <div className="error-modal-desc">
            If the other player doesn't select the cards in 180 secs you will be
            declared as the winner
          </div>
          <button className="won-modal-btn" onClick={this.loaderModalClose}>
            OK
          </button>
        </ErrorModal>
      );
    }

    return modal;
  };

  render() {
    const { sliderCardData } = this.state;
    const mobile = window.innerWidth;
    return (
      <div id="main-page">
        <MetaDecorator title={pageTitle} />
        <div className="container">
          <div className="mainpage-tittle">Exotic Battles</div>
          <nav className="mainpage-navbar">
            <ul>
              <li onClick={this.logout}>
                <a href="#">
                  <i className="fas fa-user-circle"></i>
                  {this.state.user}
                  {this.state.userBalance &&
                    " (" + this.state.userBalance + ")"}
                </a>
              </li>
              <li>
                <Link to="/wallet">
                  <i className="fas fa-wallet"></i>
                  {this.state.wallet}
                </Link>
              </li>
              <li>
                <Link to="/rules">
                  <i className="fas fa-tasks"></i>
                  {this.state.rules}
                </Link>
              </li>
            </ul>
          </nav>
          <div className="mainpage-game-container">
            <div className="mainpage-game-left-side">
              <div
                className="game-box game-box1"
                style={{
                  backgroundImage:
                    "url(" + this.state.selectedCards[0].img + ")",
                  backgroundSize: "cover",
                }}
              >
                <div
                  className="game-box-add-icon"
                  onClick={this.addIconModalShow1}
                >
                  <i className="fas fa-plus"></i>
                </div>
                <AddIconModal
                  show={this.state.addModal1}
                  modalClosed={this.addIconModalClose1}
                >
                  <Carousel
                    itemsToShow={mobile < 768 ? 1 : 3}
                    pagination={false}
                  >
                    {sliderCardData.map((items, index) => {
                      return (
                        <Slider
                          key={index}
                          scImg={items.img}
                          scTitle={items.name}
                          cardid={items.cardid}
                          qty={items.quality}
                          rarity={items.rarity}
                          selectedCard={() =>
                            this.selectedCardHandler(index, 1)
                          }
                        />
                      );
                    })}
                  </Carousel>
                </AddIconModal>
                <div
                  className="game-box-title"
                  style={
                    this.state.selectedCards[0].img != null
                      ? { opacity: 0 }
                      : { opacity: 1 }
                  }
                >
                  a or b
                </div>
                <div className="card-type-text">
                  {this.state.selectedCards[0].quality}
                </div>
              </div>

              <div
                className="game-box game-box2"
                style={{
                  backgroundImage:
                    "url(" + this.state.selectedCards[1].img + ")",
                  backgroundSize: "cover",
                }}
              >
                <div
                  className="game-box-add-icon"
                  onClick={this.addIconModalShow2}
                >
                  <i className="fas fa-plus"></i>
                </div>
                <AddIconModal
                  show={this.state.addModal2}
                  modalClosed={this.addIconModalClose2}
                >
                  <Carousel
                    itemsToShow={mobile < 768 ? 1 : 3}
                    pagination={false}
                  >
                    {sliderCardData.map((items, index) => {
                      return (
                        <Slider
                          key={index}
                          scImg={items.img}
                          scTitle={items.name}
                          cardid={items.cardid}
                          qty={items.quality}
                          rarity={items.rarity}
                          selectedCard={() =>
                            this.selectedCardHandler(index, 2)
                          }
                        />
                      );
                    })}
                  </Carousel>
                </AddIconModal>
                <div
                  className="game-box-title"
                  style={
                    this.state.selectedCards[1].img != null
                      ? { opacity: 0 }
                      : { opacity: 1 }
                  }
                >
                  a or b
                </div>
                <div className="card-type-text">
                  {this.state.selectedCards[1].quality}
                </div>
              </div>
              <div
                className="game-box game-box3"
                style={{
                  backgroundImage:
                    "url(" + this.state.selectedCards[2].img + ")",
                  backgroundSize: "cover",
                }}
              >
                <div
                  className="game-box-add-icon"
                  onClick={this.addIconModalShow3}
                >
                  <i className="fas fa-plus"></i>
                </div>
                <AddIconModal
                  show={this.state.addModal3}
                  modalClosed={this.addIconModalClose3}
                >
                  <Carousel
                    itemsToShow={mobile < 768 ? 1 : 3}
                    pagination={false}
                  >
                    {sliderCardData.map((items, index) => {
                      return (
                        <Slider
                          key={index}
                          scImg={items.img}
                          scTitle={items.name}
                          cardid={items.cardid}
                          qty={items.quality}
                          rarity={items.rarity}
                          selectedCard={() =>
                            this.selectedCardHandler(index, 3)
                          }
                        />
                      );
                    })}
                  </Carousel>
                </AddIconModal>
                <div
                  className="game-box-title"
                  style={
                    this.state.selectedCards[2].img != null
                      ? { opacity: 0 }
                      : { opacity: 1 }
                  }
                >
                  a or b
                </div>
                <div className="card-type-text">
                  {this.state.selectedCards[2].quality}
                </div>
              </div>
              <div className="left-side-button-container">
                <button className="auto-play" onClick={this.autoPlay}>
                  Auto Select
                </button>
                <button className="play" onClick={this.playGame}>
                  Play
                </button>
                {this.handleModal()}
              </div>
              <div className="left-side-fee-text">
                <span>Fee: </span>
                <span> {(+this.apiService.gameFeeAmount).toFixed(0) + " " + this.apiService.gameFeeToken} </span>
                <span>/game</span>
              </div>
            </div>
            <div className="mianpage-game-vs-container">VS</div>
            {(this.state.selectedCards2[0].img != null &&
              this.state.selectedCards[0].img != null) ||
              this.state.isDraw ? (
                <div className="mainpage-game-left-side">
                  {this.state.user2 != "" && <div> {this.state.user2} </div>}
                  <div
                    className="game-box game-box1"
                    style={{
                      backgroundImage:
                        "url(" + this.state.selectedCards2[0].img + ")",
                      backgroundSize: "cover",
                    }}
                  />
                  <div
                    className="game-box game-box2"
                    style={{
                      backgroundImage:
                        "url(" + this.state.selectedCards2[1].img + ")",
                      backgroundSize: "cover",
                    }}
                  />
                  <div
                    className="game-box game-box3"
                    style={{
                      backgroundImage:
                        "url(" + this.state.selectedCards2[2].img + ")",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
              ) : (
                <div className="mainpage-game-right-side">
                  <div className="right-side-title">
                    {this.state.activePlayers > 0
                      ? this.state.activePlayers - 1
                      : 0}{" "}
                  Active Players
                </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.myGameStatus);
  }
}
