import React, { Component } from "react";
import Routing from "./routing/Routing";
import "./App.scss";
import { Anchor } from 'ual-anchor'
import { Wax } from '@eosdacio/ual-wax';
import { UALProvider, withUAL } from 'ual-reactjs-renderer'

const chain = {
  chainId: process.env.REACT_APP_CHAINID,
  name: 'WAX',
  rpcEndpoints: [{
    protocol: 'https',
    host: process.env.REACT_APP_RPC_HOST,
    port: 443,
  }]
}

const anchor = new Anchor([chain], {
  appName: process.env.REACT_APP_APPNAME,
  service: 'https://cb.anchor.link'
})

const wax = new Wax([chain], {
  appName: process.env.REACT_APP_APPNAME,
})


export default class App extends Component {
  render() {
    let authenticators = [anchor];
    if (process.env.REACT_APP_ENV == "production")
      authenticators.push(wax);

    return (
      <UALProvider
        appName={process.env.REACT_APP_APPNAME}
        authenticators={authenticators}
        chains={[chain]}
        key={chain.chainId}
      >
        <AppConsumer />
      </UALProvider>
    );
  }
}

const AppConsumer = withUAL(Routing)

