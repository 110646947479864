import { element, string } from "prop-types";
import React, { Component } from "react";


import { Api, JsonRpc, RpcError } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';


const chainIdTest = process.env.REACT_APP_CHAINID;
const defaultPrivateKeyTest = process.env.REACT_APP_DEFAULT_KEY;
const signatureProviderTest = new JsSignatureProvider([defaultPrivateKeyTest]);
const urlTest = process.env.REACT_APP_RPC
const rpcTest = new JsonRpc(urlTest, { fetch });
const apiTest = new Api({ rpc: rpcTest, signatureProvider: signatureProviderTest, chainId: chainIdTest });


export default class ApiServices extends Component {
  constructor(props) {
    super(props);
    this.gpkBContract = process.env.REACT_APP_GPKB_CONTRACT;
    this.gpkPlayerTable = 'players';
    this.gpkGameTable = 'ongamestat';
    this.gpkUserGameTable = 'usergamestat';
    this.simpleassetsContract = 'simpleassets';
    this.sassetsTable = 'sassets';
    this.escrowContract = process.env.REACT_APP_GPKB_ESCROW_CONTRACT;
    this.cardWalletTable = 'cardwallet';
    this.gameFeeToken = 'WAX';
    this.gameFeeAmount = '1.00000000';
    this.gameFee = this.gameFeeAmount + " " + this.gameFeeToken;
    this.systemKeysPermission = "systemkeys";

    this.api = apiTest;
  }

  isLogin = () => {
    return localStorage.getItem('UALInvalidateAt');
  }

  getlastGame = () => {
    return localStorage.getItem('lastgame');
  }

  setlastGame = (status) => {
    return localStorage.setItem('lastgame', status);
  }

  isShownDrawModal = () => {
    return localStorage.getItem('downmodal');
  }

  getShownDrawModal = (status) => {
    return localStorage.setItem('downmodal', status);
  }

  getStatus = () => {
    return localStorage.getItem('gamestatus');
  }

  setStatus = (status) => {
    return localStorage.setItem('gamestatus', status);
  }

  getData = () => {
    return localStorage.getItem('getData');
  }

  setData = (data) => {
    return localStorage.setItem('getData', data);
  }


  setUserName = (userName) => {
    return localStorage.setItem('username', userName);
  }

  getAccountName = (ual) => {
    // let userName = localStorage.getItem('username');

    // if (userName)
    //   return userName
    // else
    if (ual.activeUser) {
      let userName = ual.activeUser.accountName
      this.setUserName(userName);
      return userName;
    }

    return ""
  }

  getActivePlayers = async (ual) => {
    let code = this.gpkBContract;
    let table = this.gpkPlayerTable;
    let scope = this.gpkBContract;
    let json = true;

    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit: -1 });
    if (res.rows.length > 0) {
      let activePlayers = res.rows[0].players_list.length;
      return activePlayers;
    }

    return 0;
  }

  getUserBalance = async (userName) => {

    var res = await apiTest.rpc.get_currency_balance('eosio.token', userName, 'WAX');
    if (res) {
      res = res.toString();
      return res.substr(0, (res.indexOf(".") + 3)) + " WAX"
    }
    return 0;
  }


  getUserCardsSimpleAssets = async (user, ual, lower_bound, limit = -1) => {
    let code = this.simpleassetsContract;
    let table = this.sassetsTable;
    let scope = user;
    let json = true;
    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit, lower_bound });
    return res.rows;
  }

  filterCards = (author, category, variant, allCards) => {
    let requiredCards = allCards.map(element => {
      if (element.author == author && element.category == category) {
        let mData = JSON.parse(element.mdata)
        if (mData.variant == variant) {
          let tempElement = element;
          tempElement.mdata = mData;
          return tempElement;
        }
      }
    });

    return requiredCards;
  }

  getUserCardsAvailableCards = async (user, ual) => {
    let code = this.escrowContract;
    let table = this.cardWalletTable;
    let scope = user;
    let json = true;
    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit: -1 });
    return res.rows;
  }


  getCardsData = async (cards, ual) => {
    let allCards = await this.getUserCardsSimpleAssets(this.escrowContract, ual);
    let cardsdata = cards.map((element) => {
      for (var i = 0; i < allCards.length; i++) {
        if (element.card_id == allCards[i].id) {
          let tempElement = allCards[i];
          tempElement.mdata = JSON.parse(allCards[i].mdata);
          tempElement.status = element.usage_status
          return tempElement;
        }
      }
    })
    return cardsdata;
  }


  getAuthorization = (actor, permission) => {
    return { actor, permission }
  }

  getAction = (contractName, actonName, data, authorization) => {
    return {
      actions: [
        {
          account: contractName,
          name: actonName,
          authorization,
          data,
        }
      ],
    }
  }

  withdrawCardsAction = (player, card_ids) => {
    let contract = this.escrowContract;
    let actionName = "withdrawbypl";
    let asset_contract_ac = "simpleassets"
    let authorization = this.getAuthorization(player, "active");
    let data = {
      player,
      asset_contract_ac,
      card_ids
    };
    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  addCardsToEscowAction = (from, assetids, memo) => {
    let contract = this.simpleassetsContract;
    let actionName = "transfer";
    let to = this.escrowContract;
    let authorization = this.getAuthorization(from, "active");
    let data = {
      from,
      to,
      assetids,
      memo
    };
    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  tokenTransferAction = (from) => {
    let contract = "eosio.token";
    let actionName = "transfer";
    let to = this.gpkBContract;
    let quantity = this.gameFee;
    let memo = "transfer " + this.gameFee + " fee for playing game"
    let authorization = this.getAuthorization(from, "active");
    let data = {
      from,
      to,
      quantity,
      memo
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  selectCards = (player, card1_id, card2_id, card3_id) => {
    let contract = this.gpkBContract;
    let actionName = 'sel3card';
    let asset_contract_ac = this.simpleassetsContract;

    let authorization = this.getAuthorization(player, "active");
    let data = {
      player,
      asset_contract_ac,
      card1_id,
      card2_id,
      card3_id
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  pairWithPlayer = (player_1) => {
    let contract = this.gpkBContract;
    let actionName = 'pairwplayer';
    let asset_contract_ac = this.simpleassetsContract;

    let authorization = this.getAuthorization(player_1, "active");
    let data = {
      player_1,
      asset_contract_ac
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  getTransactionDetails = async (id, ual) => {
    return await apiTest.rpc.history_get_transaction(id, 46632826) //getTransaction
  }

  getGameId = async (id, ual) => {
    var trxDetails = await this.getTransactionDetails(id, ual);
    var gameIdMessage = trxDetails.traces[1].act.data.message;
    var gameId = gameIdMessage.substr(gameIdMessage.lastIndexOf("game_id:") + 9, gameIdMessage.length)
    return gameId;
  }

  play = (player, game_id) => {
    let contract = this.gpkBContract;
    let actionName = 'play';
    let authorization = this.getAuthorization(this.gpkBContract, this.systemKeysPermission);
    let data = {
      game_id,
      player
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  }

  getGameResult = async (ual, gameId) => {

    let rows = await this.getGameRow(ual);
    if (rows.length > 0) {
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].game_id == gameId)
          return rows[i];
      }
    }
    return rows;
  }

  getGameRow = async (ual) => {
    let code = this.gpkBContract;
    let table = this.gpkGameTable;
    let scope = this.gpkBContract;
    let json = true;
    let rows = [];
    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit: -1 });
    if (res.rows.length > 0) {
      rows = res.rows;
    }
    return rows;
  }

  getGameRowSpecific = async (ual, gameId) => {
    let code = this.gpkBContract;
    let table = this.gpkGameTable;
    let scope = this.gpkBContract;
    let json = true;
    let rows = [];
    let lower_bound = gameId;
    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit: 1, lower_bound });
    if (res.rows.length > 0) {
      rows = res.rows;
    }
    return rows;
  }


  disburseAction = (game_id) => {
    let contract = this.gpkBContract;
    let actionName = 'disndcards';
    let authorization = this.getAuthorization(this.gpkBContract, this.systemKeysPermission);
    let data = {
      game_id,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    console.log("Action = ", action);
    return action;
  }

  getUserGameStatus = async (ual, user) => {
    let code = this.gpkBContract;
    let table = this.gpkUserGameTable;
    let scope = user;
    let json = true;

    let rows = [];
    var res = await apiTest.rpc.get_table_rows({ json, code, scope, table, limit: -1 });
    if (res.rows.length > 0) {
      rows = res.rows;
      return rows;
    }
    return 0;
  }

  del1drawgameAction = (game_id, defaulter_pl_list) => {
    let contract = this.gpkBContract;
    let actionName = 'del1drawgame';
    let authorization = this.getAuthorization(this.gpkBContract, this.systemKeysPermission);
    let data = {
      game_id,
      defaulter_pl_list
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    console.log("Action = ", action);
    return action;
  }


  pushTransaction = async (trx, ual) => {
    let res = {
      success: false,
      message: "",
    };
    try {
      const result = await ual.activeUser.signTransaction(
        trx,
        {
          blocksBehind: 12,
          expireSeconds: 120,
          broadcast: true,
          sign: true,
        }
      );

      res.message = result;
      res.success = true;

    } catch (e) {
      console.log("error : ", e)
      if (e.message.toString().includes("balance"))
        res.message = "Kindly buy more WAX to play the game";
      else if (e.message.toString().includes("CPU"))
        res.message = "Kindly stake more CPU to play the game";
      else if (e.message.toString().includes("net0"))
        res.message = "Kindly stake more NET to play the game";
      else
        res.message = e.message;

      res.success = false;
    }

    return res;
  }

  defaultPushAction = async (trx) => {
    let res = {
      success: false,
      message: "",
    };

    try {
      console.log("trx = ", trx)
      const result = await this.api.transact(
        trx, {
        blocksBehind: 3,
        expireSeconds: 30,
      });
      res.message = result;
      res.success = true;
    } catch (e) {
      console.log('\nCaught exception: ', e);
      res.success = false;
      res.message = "Transaction Faild";
      if (e.json) {
        var errorJson = e.json.error.details[0].message;
        if (e instanceof RpcError)
          res.message = errorJson;
      }

    }

    return res;
  }












}
