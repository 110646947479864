import React, { Component } from "react";
import CardImg42a from "../assets/images/42a.jpg";
import CardImg45 from "../assets/images/45.jpg";
import CardImg53b from "../assets/images/53b.gif";
import CardImg72b from "../assets/images/72b.gif";
import CardImg64b from "../assets/images/64b.gif";
import { Link, useHistory } from "react-router-dom";
import ApiServices from "../services/api-services";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";

const pageTitle = "Login -GPK Battles";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.cardRef = null;

    this.apiSerivce = new ApiServices();
  }

  login = () => {
    this.props.ual.showModal();
  };

  shouldComponentUpdate() {
    if (this.props.ual.activeUser) {
      window.location = "/mainpage";
    }
    return false;
  }

  render() {
    return (
      <div id="home">
        <MetaDecorator title={pageTitle} />
        <div className="container">
          <div className="card-img-container">
            <div className="cards" ref={(el) => (this.cardRef = el)}>
              <img className="card" src={CardImg42a} alt="" />
              <img className="card" src={CardImg45} alt="" />
              <img className="card" src={CardImg53b} alt="" />
              <img className="card" src={CardImg72b} alt="" />
              <img className="card" src={CardImg64b} alt="" />
            </div>
            <div className="card-img-tittle">GPK Battles</div>
            <div className="wax-cloud-wallet" onClick={this.login}>
              {/* <img src={waxLogo} alt="" className="logoWhite" /> */}
              <Link className="link" to="#">
                Login
              </Link>
              {/* <i className="fas fa-chevron-right"></i> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
