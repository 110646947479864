import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class MetaDecorator extends Component {
  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
        <meta
          name="description"
          content={"Welcome to GPK Battles " + this.props.title}
        />
      </Helmet>
    );
  }
}
