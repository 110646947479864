import React, { Component } from "react";
import Backdrop from "./backdrop/Backdrop";
// import Slider from "./modals/Slider";
// import Carousel from "react-elastic-carousel";
// import Img1 from "../assets/images/12a.gif";
// import Img2 from "../assets/images/9a.gif";
// import Img3 from "../assets/images/42a.jpg";
// import Img4 from "../assets/images/53b.gif";
// import Img5 from "../assets/images/64b.gif";
// import Img6 from "../assets/images/72b.gif";

export default class FeeModal extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     sliderCardData: [
  //       {
  //         img: Img1,
  //         title: "Political Junkie Joshua",
  //         cardid: "12",
  //         qty: "a",
  //         rarity: "RarityBASE",
  //       },
  //       {
  //         img: Img2,
  //         title: "Lenient Lauren",
  //         cardid: "9",
  //         qty: "a",
  //         rarity: "RarityBASE",
  //       },
  //       {
  //         img: Img3,
  //         title: "Patty Putty",
  //         cardid: "42",
  //         qty: "a",
  //         rarity: "RarityBASE",
  //       },
  //       {
  //         img: Img4,
  //         title: "Live Mike",
  //         cardid: "53",
  //         qty: "b",
  //         rarity: "RarityBASE",
  //       },
  //       {
  //         img: Img5,
  //         title: "Luke Warm",
  //         cardid: "64",
  //         qty: "b",
  //         rarity: "RarityBASE",
  //       },
  //       {
  //         img: Img6,
  //         title: "Brainy Brain",
  //         cardid: "72",
  //         qty: "b",
  //         rarity: "RarityBASE",
  //       },
  //     ],
  //     selectedCard: "",
  //   };
  // }

  // selectedCardHandler = (index) => {
  //   var selectedCard = this.state.sliderCardData[index];
  //   this.setState({ selectedCard });
  //   console.log("Select ADD Icon = ", selectedCard);
  // };

  render() {
    // const { sliderCardData } = this.state;

    return (
      <div>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
        <div
          className="fee-modal"
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(-220vh)",
            opacity: this.props.show ? "1" : "0",
          }}
        >
          {/* <Carousel itemsToShow={3} pagination={false}>
            {sliderCardData.map((items, index) => {
              return (
                <Slider
                  key={index}
                  scImg={items.img}
                  scTitle={items.title}
                  cardid={items.cardid}
                  qty={items.qty}
                  rarity={items.rarity}
                  selectedCard={() => this.selectedCardHandler(index)}
                />
              );
            })}
          </Carousel> */}
          {this.props.children}
        </div>
        {/* {!this.state.selectedCard == 0 && (
          <img
            src={this.state.selectedCard.img}
            alt=""
            className="selected-card-img"
          />
        )} */}
      </div>
    );
  }
}
