import React, { Component } from "react";

export default class Slider extends Component {
  render() {
    return (
      <div className="slider-card-container" onClick={this.props.selectedCard}>
        <div className="slider-card-img">
          <img src={this.props.scImg} alt="" />
        </div>
        <div className="slider-card-title">{this.props.scTitle}</div>
        <div className="slider-card-desc">
          <div className="slider-card-number">
            <span>#{this.props.cardid}</span>
            {this.props.qty}
          </div>
          <div className="slider-card-rarity">{this.props.rarity}</div>
        </div>
      </div>
    );
  }
}
