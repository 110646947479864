import React, { Component } from "react";
import { Link } from "react-router-dom";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";

const pageTitle = "Rules -GPK Battles";

export default class Rules extends Component {
  render() {
    return (
      <div id="rules">
        <MetaDecorator title={pageTitle} />
        <div className="container">
          <div className="wallet-tittle">Exotic Battles</div>
          <div className="wallet-container">
            <div className="wallet-heading">Rules</div>
            <div className="wallet-cards-container">
              <h3>Game Play</h3>
              <p>
                Exotic Battles is a simple game of chance card game using GPK
                Exotic cards. The cards have type a (or) b and each game
                deciding factor is based on WAX Random Number Generator(RNG)
                smart contract. The winning player has the highest number of
                that card type.
              </p>
              <h3>Game Steps</h3>
              <p>
                1) Transfer cards to the Escrow wallet <br />
                2) Select cards <br />
                3) Pay the Fee <br />
                4) Play and Win a card
              </p>
            </div>
            <div className="wallet-button-container">
              <button className="play">
                <Link to="/mainpage">Go to Game</Link>
              </button>
            </div>
            <div className="wallet-cross-btn">
              <Link to="/mainpage">
                <i className="far fa-times-circle"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
