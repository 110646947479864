import { element } from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ErrorModal from "../components/ErrorModal";
import ApiServices from "../services/api-services";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";

const pageTitle = "Wallet -GPK Battles";

export default class Wallet extends Component {
  constructor(props) {
    super(props);
    this.cardRef = null;
    this.ipfsUrl = "https://ipfs.io/ipfs/";
    this.state = {
      user: "",
      isLoading: true,
      errorMessage: "",
      transactionFaildModal: false,
      userCardData: [],
      walletCardData: [],
      selectedWalletCards: [],
      selectedUserCards: [],
      // availableCardds: []
    };

    this.apiService = new ApiServices();
  }

  async componentDidMount() {
    let ual = this.props.ual;
    if (ual.activeUser) {
      let user = this.apiService.getAccountName(ual);
      this.setState({ user });
      await this.getData(user, ual);
      this.setState({ isLoading: false });
    }
  }

  getData = async (user, ual) => {
    await this.getUserCards(user, ual);
    await this.getUserAvailableCards(user, ual);
  };
  getUserCards = async (user, ual) => {
    let allCards = await this.apiService.getUserCardsSimpleAssets(user, ual);
    if (allCards.length > 0) {
      let requiredCards = await this.apiService.filterCards(
        process.env.REACT_APP_AUTHOR,
        "exotic",
        "base",
        allCards
      );
      requiredCards = requiredCards.filter(function (el) {
        return el != null;
      });
      let userCardData = requiredCards.map((element, index) => {
        return {
          img: this.ipfsUrl + element.mdata.img,
          name: element.mdata.name,
          cardid: element.mdata.cardid,
          quality: element.mdata.quality,
          rarity: element.mdata.variant,
          key: element.id,
        };
      });
      this.setState({ userCardData });
    }
  };

  getUserAvailableCards = async (user, ual) => {
    let allCards = await this.apiService.getUserCardsAvailableCards(user, ual);
    let avaiableCards = [];
    for (var i = 0; i < allCards.length; i++) {
      if (allCards[i].usage_status == "available") {
        avaiableCards.push(allCards[i]);
      }
    }

    let cards = await this.apiService.getCardsData(avaiableCards, ual);
    cards = cards.filter(function (el) {
      return el != null;
    });

    if (cards.length > 0) {
      let walletCardData = cards.map((element, index) => {
        return {
          img: this.ipfsUrl + element.mdata.img,
          name: element.mdata.name,
          cardid: element.mdata.cardid,
          quality: element.mdata.quality,
          rarity: element.mdata.variant,
          key: element.id,
        };
      });
      this.setState({ walletCardData });
    }
  };

  addCards = async () => {
    if (!this.state.isLoading) {
      if (this.state.selectedUserCards.length > 0) {
        let cardsIds = this.state.selectedUserCards.map((element) => {
          return this.state.userCardData[element].key;
        });
        let memo = "transfer cards for gpk.battles playing";
        const addCardEscrowAction = this.apiService.addCardsToEscowAction(
          this.state.user,
          cardsIds,
          memo
        );
        const result = await this.apiService.pushTransaction(
          addCardEscrowAction,
          this.props.ual
        );
        if (result.success) {
          window.location.reload();
        } else {
          this.setState({
            transactionFaildModal: true,
            errorMessage: result.message,
          });
          console.log("Transaction failed = ", result.message);
        }
      }
    }
  };

  withdrawCards = async () => {
    if (!this.state.isLoading) {
      if (this.state.selectedWalletCards.length > 0) {
        let cardsIds = this.state.selectedWalletCards.map((element) => {
          return this.state.walletCardData[element].key;
        });
        const withdrawAction = this.apiService.withdrawCardsAction(
          this.state.user,
          cardsIds
        );
        const result = await this.apiService.pushTransaction(
          withdrawAction,
          this.props.ual
        );
        if (result.success) {
          window.location.reload();
        } else {
          this.setState({
            transactionFaildModal: true,
            errorMessage: result.message,
          });
          console.log("Transaction failed = ", result.message);
        }
      }
    }
  };

  handleCards = (type, index) => {
    let selectedUserCards = this.state.selectedUserCards;
    let selectedWalletCards = this.state.selectedWalletCards;
    if (type == "userCards") {
      selectedUserCards = this.handleIndex(selectedUserCards, index);
    } else {
      selectedWalletCards = this.handleIndex(selectedWalletCards, index);
    }

    this.setState({ selectedUserCards, selectedWalletCards });
  };

  handleIndex = (card, index) => {
    let tempCard = card;
    if (tempCard.includes(index)) tempCard.splice(tempCard.indexOf(index), 1);
    else tempCard.push(index);

    return tempCard;
  };

  selectAllCards = (type) => {
    let userCards = this.state.userCardData;
    let walletCards = this.state.walletCardData;

    let selectedUserCards = this.state.selectedUserCards;
    let selectedWalletCards = this.state.selectedWalletCards;

    let isSelectedUser =
      selectedUserCards.length == userCards.length ? true : false;
    let isSelectedWallet =
      selectedWalletCards.length == walletCards.length ? true : false;
    if (type == "userCards") {
      for (var i = 0; i < userCards.length; i++) {
        if (!isSelectedUser) selectedUserCards[i] = i;
        else selectedUserCards.splice(i);
      }
    } else {
      for (var i = 0; i < walletCards.length; i++) {
        if (!isSelectedWallet) selectedWalletCards[i] = i;
        else selectedWalletCards.splice(i);
      }
    }

    this.setState({ selectedUserCards, selectedWalletCards });
  };

  transactionFaildModalClose = () => {
    this.setState({ transactionFaildModal: false });
  };

  render() {
    const mobile = window.innerWidth;
    const userCardsData = this.state.userCardData.map((items, index) => {
      return (
        <div className="wallet-card-data-container" key={index}>
          <div className="checkbox">
            <input
              type="checkbox"
              checked={this.state.selectedUserCards.includes(index)}
              onClick={() => this.handleCards("userCards", index)}
            />
          </div>
          <img src={items.img} alt={items.name} />
          <div className="wallet-card-qty">
            {items.cardid}
            {items.quality}
          </div>
          <div className="wallet-card-title">{items.name}</div>
        </div>
      );
    });

    const walletCardsData = this.state.walletCardData.map((items, index) => {
      return (
        <div className="wallet-card-data-container" key={index}>
          <div className="checkbox">
            <input
              type="checkbox"
              checked={this.state.selectedWalletCards.includes(index)}
              onChange={() => this.handleCards("walletCards", index)}
            />
          </div>
          <img src={items.img} alt={items.name} />
          <div className="wallet-card-qty">
            {items.cardid}
            {items.quality}
          </div>
          <div className="wallet-card-title">{items.name}</div>
        </div>
      );
    });
    return (
      <div id="wallet">
        <MetaDecorator title={pageTitle} />
        <div className="container">
          <div className="wallet-tittle">Exotic Battles</div>
          <div className="wallet-container">
            <div className="wallet-heading">Wallet</div>
            <div className="wallet-cards-container">
              <div className="wallet-card-left-side">
                <div className="wallet-user">
                  <a>
                    <i className="fas fa-user-circle"></i>
                    {this.state.user}
                  </a>
                  <div onClick={() => this.selectAllCards("userCards")}>
                    <span>{this.state.userCardData.length} Cards</span>
                  </div>
                </div>
                <div className="wallet-data-container">{userCardsData}</div>
              </div>
              <div className="wallet-card-controlers-container">
                <div className="wallet-add-card controler-btn">
                  <button onClick={this.addCards}>
                    <i className="fas fa-play-circle"></i>
                  </button>
                  <p>Add Cards</p>
                </div>
                <div className="wallet-withdraw-card controler-btn">
                  <button onClick={this.withdrawCards}>
                    <i
                      className="fas fa-play-circle play-rotate"
                      // style={
                      //   mobile < 577
                      //     ? { transform: "rotate(150deg)" }
                      //     : { transform: "rotate(180deg)" }
                      // }
                    ></i>
                  </button>
                  <p>Withdraw</p>
                </div>
              </div>
              <div className="wallet-card-left-side">
                <div className="wallet-user">
                  <a>
                    <i className="fas fa-wallet"></i>
                    gpkbatescrow
                  </a>
                  <div onClick={() => this.selectAllCards("walletCards")}>
                    <span>{this.state.walletCardData.length} Cards</span>
                  </div>
                </div>
                <div className="wallet-data-container">{walletCardsData}</div>
              </div>
            </div>
            <div className="wallet-button-container">
              <button className="play">
                <Link to="/mainpage">Go to Game</Link>
              </button>
            </div>
            <div className="wallet-info-container">
              <p>
                To play the game, you need at-least 3 cards, in these
                combinations 2-a, 1-b or 2-b,1-a quality
              </p>
            </div>
            <div className="wallet-cross-btn">
              <Link to="/mainpage">
                <i className="far fa-times-circle"></i>
              </Link>
            </div>
          </div>
        </div>
        {this.state.transactionFaildModal && (
          <ErrorModal show={this.state.transactionFaildModal}>
            <div className="error-modal-title">Transaction Failed</div>
            <div className="error-modal-title">{this.state.errorMessage}</div>
            <button
              className="won-modal-btn"
              onClick={this.transactionFaildModalClose}
            >
              OK
            </button>
          </ErrorModal>
        )}
      </div>
    );
  }
}
